<template>
  <div id="dnptcalc-position">
    <div v-for="(option, idx) in optionItems"
         :key="idx"
         :class="{'selected': selectedOption === idx}"
    >
      <img
          :src="'/wp-content/plugins/dnpt-calc/widget/dist/img/' + idx + '.png'"
          v-bind:alt="option.toString()"
          @click="handleImageClick(idx)">
      <div class="dnptcalc-position-img-caption">{{optionItems[idx]}}</div>
    </div>
  </div>
  <div id="dnptcalc-dimensions">
    <div>
      <label for="dnptcalc-height">Höhe [in mm]</label>
      <input
          id="dnptcalc-height"
          @change="calculate"
          @click="calculate"
          @keyup="calculate"
          v-model.number="height"
          type="number"
          min="0"
          step="1"
          placeholder="z.B. 1200"/>
    </div>

    <div>
      <label for="dnptcalc-width">Breite [in mm]</label>
      <input
          id="dnptcalc-width"
          @change="calculate"
          @click="calculate"
          @keyup="calculate"
          v-model.number="width"
          type="number"
          min="0"
          step="1"
          placeholder="z.B. 600"
      />
    </div>

    <div>
      <label for="dnptcalc-depth">Tiefe [in mm]</label>
      <input
          id="dnptcalc-depth"
          v-model.number="depth"
          @change="calculate"
          @click="calculate"
          @keyup="calculate"
          class
          type="number"
          min="0"
          step="1"
          placeholder="z.B. 400"
      />
    </div>
  </div>

  <div id="dnptcalc-material">
    <div id="dnptcalc-material-v1">
      <label for="dnptcalc-material-dropdown">Materialart</label>
      <select id="dnptcalc-material-dropdown" v-model="selectedMaterial">
        <option v-for="(material, idx) in materials" v-bind:value="idx" :key="idx">
          {{ material.text }}
        </option>
      </select>
    </div>
    <div id="dnptcalc-material-v2">
      <label for="dnptcalc-material-radio">Materialart</label>
      <ul id="dnptcalc-material-radio">
        <li v-for="(material, idx) in materials" :key="idx">
          <input type="radio" v-model="selectedMaterial" :value="idx"> {{ material.text }}
        </li>
      </ul>
    </div>
  </div>
  <div id="dnptcalc-insulation">
    <label for="insulation">Isolierdicke [in mm]</label>
    <input
        id="insulation"
        @change="calculate"
        @click="calculate"
        @keyup="calculate"
        v-model.number="insulationThickness"
        type="number"
        min="0"
        step="1"
        placeholder="0"
    />
  </div>
  <div id="dnptcalc-temp">
    <div>
      <label for="maxAmbientTemperature">maximale Umgebungstemperatur [in °C]</label>
      <input
          id="maxAmbientTemperature"
          @change="calculate"
          v-model.number="maxAmbientTemperature"
          type="number"
          min="0"
          placeholder="z.B. 35"
        />
    </div>
    <div>
      <label for="knownThermalLeakagePower">Verlustleistung [in W]</label>
      <input
          id="knownThermalLeakagePower"
          @change="calculate"
          @click="calculate"
          @keyup="calculate"
          v-model.number="knownThermalLeakagePower"
          type="number"
          min="0"
          placeholder="z.B. 100"
        />
    </div>
    <div>
      <label for="maxInsideTemperature">maximale Innentemperatur [in °C]</label>
      <input
          id="maxInsideTemperature"
            @change="calculate"
            v-model.number="maxInsideTemperature"
            type="number"
            min="0"
            placeholder="z.B. 50"
        />
    </div>
  </div>

  <div id="dnptcalc-acdc">
    <label for="dnptcalc-acdc">Stromart</label>
    <input type="checkbox" id="dc" value="DC" v-model="kindOfCurrent" @change="calculate">
    <label for="dc">DC</label>
    <input type="checkbox" id="ac" value="AC" v-model="kindOfCurrent" @change="calculate">
    <label for="ac">AC</label>
  </div>

  <div id="dnptcalc-amount">
    <label for="dnptcalc-amount-input">Geräteanzahl [in Stück]</label>
    <input
        id="dnptcalc-amount-input"
        @change="calculate"
        @click ="calculate"
        @keyup="calculate"
        v-model.number="amountDevices"
        type="number"
        min="1"
        step="1"
        placeholder="z.B. 2"
    />
  </div>

  <div id="dnptcalc-results">
    <table>
      <tr>
        <th class="dnptcalc-results-table-col-name">Gerät</th>
        <th class="dnptcalc-results-table-col-type">Typ</th>
        <th class="dnptcalc-results-table-col-input">Aufnahmeleistung</th>
        <th class="dnptcalc-results-table-col-temp">Innentemperatur</th>
        <th class="dnptcalc-results-table-col-req"></th>
        <th class="dnptcalc-results-table-col-link"></th>
      </tr>
      <tr v-for="(device, idx) in matchingDevices" :key="idx">
        <td class="dnptcalc-results-table-col-name"><a v-bind:href="'/produkt/'+ device.path + '#groesse-' + device.size">{{device.name}}</a></td>
        <td class="dnptcalc-results-table-col-type">{{device.type}}</td>
        <td class="dnptcalc-results-table-col-input">{{device.ratedInput}} W</td>
        <td class="dnptcalc-results-table-col-temp">{{device.innerTemp.toFixed(1)}} °C</td>
        <td class="dnptcalc-results-table-col-req"><a v-bind:href="this.requestUrl+'&Auswahl='+device.type">Anfragen</a></td>
        <td class="dnptcalc-results-table-col-link">
          <span class="avia_button_icon avia_button_icon_left " aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello"></span>
          <a v-bind:href="'/warenkorb?add-to-cart='+ device.id + '&quantity=' + amountDevices">in den Warenkorb</a>
        </td>
      </tr>
    </table>
    <div v-if="!matchingDevices">Es werden derzeit keine Geräte aufgelistet, da noch Angaben zur Berechnung fehlen</div>
  </div>

  <div id="dnptcalc-request-btn">
    <a v-bind:href="requestUrl">individuelle Lösung anfragen</a>
  </div>
</template>

<script>
  import CalculatorService from "./services/CalculatorService";

  export default {
    name: 'dnpt-calculator-widget',
    data: () => ({
      headers: [
        {
          text: 'Gerät',
          align: 'left',
          sortable: false,
          value: 'type',
        },
        {text: 'Bestellnr.', value: 'orderNr'},
        {text: 'Aufnahmeleistung', value: 'ratedInput'},
        {text: 'Innentemperatur', value: 'innerTemp'},
      ],
      optionItems: [
        "Einzelgehäuse, freistehend" // 0
        , "Anfangs- oder Endgehäuse, freistehend" // 1
        , "Mittelgehäuse, freistehend" // 2
        , "Einzelgehäuse, Wandanbau" // 3
        , "Anfangs- oder Endgehäuse, Wandandbau" // 4
        , "Mittelgehäuse, Wandanbau" // 5
        , "Mittelgehäuse, Wandanbau mit abgedeckter Dachfläche" // 6
      ],
      selectedOption: 0,

      materials: [
        {text: "Stahl, lackiert", data: 5.5}
        , {text: "Edelstahl", data: 4.5}
        , {text: "Aluminium", data: 12}
        , {text: "Aluminium doppelwandig", data: 4.5}
        , {text: "Kunststoff", data: 3.5}
      ],
      selectedMaterial: 2,
      //height: null,
      height: 1200,
      //width: null,
      width: 600,
      //depth: null,
      depth: 300,
      calculatedSurface: null,
      message: null,

      //insulationThickness: null,
      insulationThickness: 0,
      overallInsulation: null,

      //maxAmbientTemperature: null,
      maxAmbientTemperature: 35,
      //maxInsideTemperature: null,
      maxInsideTemperature: 50,

      calculatedThermalLeakagePower: null,
      //knownThermalLeakagePower: null,
      knownThermalLeakagePower: 100,

      amountDevices: 1,
      selectedIP: 67,

      kindOfCurrent: ["DC"],

      matchingDevices: null,
      requestUrl: "",
    }),
    methods: {
      handleImageClick(selectedOption) {
        /* the following would allow deselection
        if (selectedOption === this.selectedOption) {
          this.selectedOption = null;
        } else {
          this.selectedOption = selectedOption;
        }
        */
        this.selectedOption = selectedOption; //no deselect
        this.calculate();
      },
      calculateSurface() {
        if (this.width !== null && this.height !== null && this.depth !== null && this.width !== '' && this.height
            !== '' && this.depth !== '') {
          switch (this.selectedOption) {
            case 0:
              this.calculatedSurface = 1.8 * this.height * (this.width + this.depth) + 1.4 * this.width * this.depth;
              this.calculatedSurface = this.calculatedSurface / 1000000;
              break;
            case 1:
              this.calculatedSurface = 1.4 * this.depth * (this.height + this.width) + 1.8 * this.width * this.height;
              this.calculatedSurface = this.calculatedSurface / 1000000;
              break;
            case 2:
              this.calculatedSurface = 1.8 * this.width * this.height + 1.4 * this.width * this.depth + this.depth * this.height;
              this.calculatedSurface = this.calculatedSurface / 1000000;
              break;
            case 3:
              this.calculatedSurface = 1.4 * this.width * (this.height + this.depth) + 1.8 * this.depth * this.height;
              this.calculatedSurface = this.calculatedSurface / 1000000;
              break;
            case 4:
              this.calculatedSurface = 1.4 * this.height * (this.width + this.depth) + 1.4 * this.width * this.depth;
              this.calculatedSurface = this.calculatedSurface / 1000000;
              break;
            case 5:
              this.calculatedSurface = 1.4 * this.width * (this.height + this.depth) + this.depth * this.height;
              this.calculatedSurface = this.calculatedSurface / 1000000;
              break;
            case 6:
              this.calculatedSurface = 1.4 * this.width * this.height + 0.7 * this.width * this.depth + this.depth * this.height;
              this.calculatedSurface = this.calculatedSurface / 1000000;
              break;
            default:
              this.surfaceCalculationError();
          }
        } else {
          this.surfaceCalculationError();
        }
      },
      calculateOverallInsulation() {
        let insulationThicknessInCm = 0
        if (this.insulationThickness !== null && this.insulationThickness !== '') {
          insulationThicknessInCm = (this.insulationThickness / 10);
        }
        this.overallInsulation = 1 / ((1 / this.materials[this.selectedMaterial].data) + (insulationThicknessInCm / 3.5));
      },
      surfaceCalculationError() {
        this.calculatedSurface = null;
        this.matchingDevices = [];
        this.message = 'Please provide the missing information'
      },
      insulationCalculationError() {
        this.overallInsulation = null;
        this.matchingDevices = [];
        this.message = 'Please provide the missing information'
      },
      addParam(params, key, val) {
        if (val !== null && val !== '') {
          params = params + "&"+key+"=" + val
        }
        return params
      },
      adjustRequestUrl() {
        let params = this.addParam("", "Position", this.optionItems[this.selectedOption])
        params = this.addParam(params, "Hoehe", this.height)
        params = this.addParam(params, "Breite", this.width)
        params = this.addParam(params, "Tiefe", this.depth)
        params = this.addParam(params, "Material", this.materials[this.selectedMaterial].text)
        params = this.addParam(params, "Isolierdicke", this.insulationThickness)
        params = this.addParam(params, "Umgebungstemperatur", this.maxAmbientTemperature)
        params = this.addParam(params, "Verlustleistung", this.knownThermalLeakagePower)
        params = this.addParam(params, "Innentemperatur", this.maxInsideTemperature)
        params = this.addParam(params, "Stromart", this.kindOfCurrent.toString())
        params = this.addParam(params, "Anzahl", this.amountDevices)
        this.requestUrl = "/individuelle-anfrage?" + params
      },
      calculate() {
        this.calculateSurface();
        this.calculateOverallInsulation();

        if (this.calculatedSurface !== null && this.overallInsulation !== null && this.maxAmbientTemperature !== null && this.maxAmbientTemperature !== ''
            && this.maxInsideTemperature !== null && this.maxInsideTemperature !== '') {
          this.calculatedThermalLeakagePower = this.calculatedSurface * this.overallInsulation * (this.maxAmbientTemperature - this.maxInsideTemperature);
          this.matchingDevices = CalculatorService.calculateMatrix(this.amountDevices, this.maxInsideTemperature, this.maxAmbientTemperature, this.knownThermalLeakagePower,
              this.overallInsulation, this.calculatedSurface, this.kindOfCurrent, this.selectedIP)
        } else {
          this.calculatedThermalLeakagePower = null;
          this.matchingDevices = [];
        }
        this.adjustRequestUrl()
      }
    },
    mounted() {
      this.calculate();
    }
  }
</script>

<style>
</style>
