let b = [
  // Standard Gr. 1
  {name: 'Schaltschrankkühler Serie Standard - 50 W, Größe 1', size: 1, path: 'schaltschrank-kuehler-standard', type: 'FL-104-C', orderNr: '2045', ip: 67, kindOfCurrent: 'DC', ambientTempRestriction: 70, ratedInput: 50,   g: -19.4,   s1: 0.850, s2: 0.435, s2Corr: null, innerTemp: null, id: 6179}
  , {name: 'Schaltschrankkühler Serie Standard - 70 W, Größe 1', size: 1, path: 'schaltschrank-kuehler-standard', type: 'FN-104-C', orderNr: '2049', ip: 67, kindOfCurrent: 'DC', ambientTempRestriction: 70, ratedInput: 70,   g: -21.6,   s1: 0.980, s2: 0.425, s2Corr: null, innerTemp: null, id: 6180}
  , {name: 'Schaltschrankkühler Serie Standard - 100 W, Größe 1', size: 1, path: 'schaltschrank-kuehler-standard', type: 'FR-104-C', orderNr: '2050', ip: 67, kindOfCurrent: 'DC', ambientTempRestriction: 70, ratedInput: 100,  g: -19.5, s1: 0.890, s2: 0.380, s2Corr: null, innerTemp: null, id: 6181}

  // Standard Gr. 2
  , {name: 'Schaltschrankkühler Serie Standard - 100 W, Größe 2', size: 2, path: 'schaltschrank-kuehler-standard', type: 'FL-208-C',  orderNr: '2051', ip: 67, kindOfCurrent: 'DC', dc: true, ambientTempRestriction: 70, ratedInput: 100, g: -20.200, s1: 0.810, s2: 0.250, s2Corr: null, innerTemp: null, id: 6182}
  , {name: 'Schaltschrankkühler Serie Standard - 140 W, Größe 2', size: 2, path: 'schaltschrank-kuehler-standard', type: 'FN-208-C',  orderNr: '2053', ip: 67, kindOfCurrent: 'DC', dc: true, ambientTempRestriction: 70, ratedInput: 140, g: -23.500, s1: 0.820, s2: 0.285, s2Corr: null, innerTemp: null, id: 6183}
  , {name: 'Schaltschrankkühler Serie Standard - 200 W, Größe 2', size: 2,path: 'schaltschrank-kuehler-standard', type: 'FR-208-C',  orderNr: '2054', ip: 67, kindOfCurrent: 'DC', dc: true, ambientTempRestriction: 70, ratedInput: 200, g: -21.600, s1: 0.830, s2: 0.235, s2Corr: null, innerTemp: null, id: 6184}

  // Standard Gr. 2 AC
  , {name: 'Schaltschrankkühler Serie AC - 100 W, Größe 2', size: 2, path: 'schaltschrankkuehler-serie-ac', type: 'FL-208-AC', orderNr: '2116',   ip: 67, kindOfCurrent: 'AC', ambientTempRestriction: 70, ratedInput: 100, g: -22.600, s1: 0.860, s2: 0.255, s2Corr: null, innerTemp: null, id: 6531}
  , {name: 'Schaltschrankkühler Serie AC - 200 W, Größe 2', size: 2, path: 'schaltschrankkuehler-serie-ac', type: 'FR-208-AC', orderNr: '003059', ip: 67, kindOfCurrent: 'AC', ambientTempRestriction: 70, ratedInput: 200, g: -26.000, s1: 0.950, s2: 0.203, s2Corr: null, innerTemp: null, id: 6532}

  // Standard Gr. 3
  , {name: 'Schaltschrankkühler Serie Standard - 200 W, Größe 3', size: 3, path: 'schaltschrank-kuehler-standard', type: 'FL-316-C', orderNr: '2052', ip: 67, kindOfCurrent: 'DC', ambientTempRestriction: 70, ratedInput: 200, g: -25.1, s1: 0.950, s2: 0.130, s2Corr: null, innerTemp: null, id: 6185}
  , {name: 'Schaltschrankkühler Serie Standard - 280 W, Größe 3', size: 3, path: 'schaltschrank-kuehler-standard', type: 'FN-316-C', orderNr: '2055', ip: 67, kindOfCurrent: 'DC', ambientTempRestriction: 70, ratedInput: 280, g: -23.1, s1: 0.940, s2: 0.123, s2Corr: null, innerTemp: null, id: 6186}
  , {name: 'Schaltschrankkühler Serie Standard - 400 W, Größe 3', size: 3, path: 'schaltschrank-kuehler-standard', type: 'FR-316-C', orderNr: '2056', ip: 67, kindOfCurrent: 'DC', ambientTempRestriction: 70, ratedInput: 400, g: -22.1, s1: 0.910, s2: 0.125, s2Corr: null, innerTemp: null, id: 6187}

  // Standard Gr. 4 AC
  , {name: 'Schaltschrankkühler Serie AC - 200 W, Größe 4', size: 4, path: 'schaltschrankkuehler-serie-ac', type: 'FL-416-AC', orderNr: '2068', ip: 67, kindOfCurrent: 'AC', ambientTempRestriction: 70, ratedInput: 200, g: -29.4, s1: 0.960, s2: 0.137, s2Corr: null, innerTemp: null, id: 6537}
  , {name: 'Schaltschrankkühler Serie AC - 400 W, Größe 4', size: 4, path: 'schaltschrankkuehler-serie-ac', type: 'FR-416-AC', orderNr: '2115', ip: 67, kindOfCurrent: 'AC', ambientTempRestriction: 70, ratedInput: 400, g: -26.8, s1: 0.950, s2: 0.097, s2Corr: null, innerTemp: null, id: 6538}

  // Standard Gr. 5 AC - alter Wert laut PDF
  , {name: 'Schaltschrankkühler Serie AC - 450 W, Größe 5', size: 5, path: 'schaltschrankkuehler-serie-ac', type: 'FL-536-AC', orderNr: '003329', ip: 67, kindOfCurrent: 'AC', ambientTempRestriction: 70, ratedInput: 450, g: -37.840, s1: 1.060, s2: 0.085, s2Corr: null, innerTemp: null, id: 6544}
  , {name: 'Schaltschrankkühler Serie AC - 900 W, Größe 5', size: 5, path: 'schaltschrankkuehler-serie-ac', type: 'FR-536-AC', orderNr: '003330', ip: 67, kindOfCurrent: 'AC', ambientTempRestriction: 70, ratedInput: 900, g: -23.400, s1: 0.920, s2: 0.054, s2Corr: null, innerTemp: null, id: 6545}

  // Standard Hochtemperatur
  , {name: 'Schaltschrankkühler Serie HL - 50 W, Größe 1', size: 1, path: 'schaltschrank-kuehler-serie-hl', type: 'HL-104-C', orderNr: '003597', ip: 55, kindOfCurrent: 'DC', ambientTempRestriction: null, ratedInput: 50,  g: -19.4, s1: 0.85, s2: 0.435, s2Corr: null, innerTemp: null, id: 6562}
  , {name: 'Schaltschrankkühler Serie HL - 100 W, Größe 2', size: 2, path: 'schaltschrank-kuehler-serie-hl', type: 'HL-208-C', orderNr: '003718', ip: 55, kindOfCurrent: 'DC', ambientTempRestriction: null, ratedInput: 100, g: -20.2, s1: 0.81, s2: 0.250, s2Corr: null, innerTemp: null, id: 6563}
  , {name: 'Schaltschrankkühler Serie HL - 200 W, Größe 3', size: 3, path: 'schaltschrank-kuehler-serie-hl', type: 'HL-316-C', orderNr: '003787', ip: 55, kindOfCurrent: 'DC', ambientTempRestriction: null, ratedInput: 200, g: -25.1, s1: 0.95, s2: 0.130, s2Corr: null, innerTemp: null, id: 6564}
];

export default {

  calculateMatrix(amountDevices, maxInsideTemperature, maxAmbientTemperature, loss, coefficient, surface, kindOfCurrent, ip) {

    let filteredMatrix = b.filter(e => {
      return (e.ambientTempRestriction === null || maxAmbientTemperature <= e.ambientTempRestriction)
          && kindOfCurrent.includes(e.kindOfCurrent)
          && e.ip === ip;
    });

    return filteredMatrix.map(x => {

      x.s2Corr = x.s2 / amountDevices;

      let upperTerm = x.g + (x.s1 * maxAmbientTemperature) + (x.s2Corr * loss) + (x.s2Corr * maxAmbientTemperature * coefficient * surface);
      let lowerTerm = 1 + (x.s2Corr * coefficient * surface);

      if (lowerTerm !== 0) {
        x.innerTemp = upperTerm / lowerTerm;
      } else {
        // eslint-disable-next-line no-console
        console.error("Division by zero not allowed")
      }
      return x;
    }).filter(x => x.innerTemp <= maxInsideTemperature);
  },

  getFirstMatchingDevice(amountDevices, maxInsideTemperature, maxAmbientTemperature, loss, coefficient, surface, dc, ip) {
    let matrix = this.calculateMatrix(amountDevices, maxInsideTemperature, maxAmbientTemperature, loss, coefficient, surface, dc, ip);
    return matrix.length > 0 ? matrix[0] : null;
  },
}